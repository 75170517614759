<template>
  <div>
    <ciam-card-content v-if="deployment.status == 'CREATING'">
      <ciam-card-content-lines>
        <ciam-card-content-line type="full-width">
          <template v-slot:content>
            <p>
              {{ $i18n.t('deployment.creationSuccess.building') }}
            </p>
            <p>
              {{ $i18n.t('deployment.creationSuccess.notificationIsComing') }}
            </p>
            <ciam-loader class="py-4"></ciam-loader>
          </template>
        </ciam-card-content-line>
      </ciam-card-content-lines>
    </ciam-card-content>
    <ciam-card-content v-else-if="deployment">
      <ciam-card-content-lines>
        <ciam-card-content-line>
          <template v-slot:label>{{ $t('deploymentDetail.url') }}</template>
          <template v-slot:content>
            <ciam-link v-if="statistics" data-e2e="keycloak-deployment-link" :href="statistics.adminUrl" target="_blank"
            >{{ statistics.adminUrl }}
            </ciam-link>
            <ciam-text v-else>...</ciam-text>
          </template>
        </ciam-card-content-line>

        <ciam-card-content-line>
          <template v-slot:label>{{ $t('deploymentDetail.version') }}</template>
          <template v-slot:content>
              <ciam-deployment-version  
                :statistics="statistics" 
                :canIUpgrade="canIUpgrade && isAdmin()"
                :deployment="deployment">
              </ciam-deployment-version>
          </template>
        </ciam-card-content-line>

        <ciam-card-content-line v-if="deployment.monitorBadgeId">
          <template v-slot:label>{{ $t('deploymentDetail.uptime') }}</template>
          <template v-slot:content>
              <ciam-uptime-badge :monitorBadgeId="deployment.monitorBadgeId"></ciam-uptime-badge>
          </template>
        </ciam-card-content-line>

        <ciam-card-content-line v-if="topologyFeatureEnabled">
          <template v-slot:label>{{ $t('deploymentDetail.publicGateway') }}</template>
          <template v-slot:content
          >
            <ciam-clipboard title="Copy public gateway Ip">
              <ciam-text class="cli"> {{ topology.gateway.publicIp }}</ciam-text>
            </ciam-clipboard>
          </template
          >
        </ciam-card-content-line>

        <ciam-card-content-line>
          <template v-slot:label>{{ $t('deploymentDetail.plan') }}</template>
          <template v-slot:content>{{ deployment.pricingPlan.label }}</template>
        </ciam-card-content-line>

        <ciam-card-content-line>
          <template v-slot:label>
            {{ $t('deploymentDetail.location') }}
          </template>
          <template v-slot:content>
            <ciam-text>{{ planLocation() }}</ciam-text>
          </template>
        </ciam-card-content-line>

        <ciam-card-content-line>
          <template v-slot:label>Organization</template>
          <template v-slot:content>
            <ciam-link :href="viewOrganization()">{{ organizationName }}</ciam-link>
          </template>
        </ciam-card-content-line>

        <ciam-card-content-line>
          <template v-slot:label>{{ $t('deploymentDetail.updatedAt') }}</template>
          <template v-slot:content>{{ deployment.lastUpdate | formatDate() }}</template>
        </ciam-card-content-line>

        <ciam-card-content-line>
          <template v-slot:label>{{ $t('deploymentDetail.nbRealm') }}</template>
          <template v-slot:content>
            <div v-if="statistics">
              <b-progress :value="getPercentRealms(statistics)" size="is-large"
                          :type="getRealmsProgressStyle(statistics)" show-value>
                <span v-text="statistics.realmsCount" /> /
                <span v-text="statistics.maxRealmsCount" />
              </b-progress>
            </div>
            <ciam-text v-else>...</ciam-text>
          </template>
        </ciam-card-content-line>

        <ciam-card-content-line>
          <template v-slot:label>{{ $t('deploymentDetail.nbUsers') }}</template>
          <template v-slot:content>
            <div v-if="statistics">
              <b-progress :value="getPercentUsers(statistics)" size="is-large" :type="getUsersProgressStyle(statistics)"
                          show-value>
                <span v-text="statistics.usersCount" /> /
                <span v-text="statistics.maxUsersCountPerRealm" />
              </b-progress>
            </div>
            <ciam-text v-else>...</ciam-text>
          </template>
        </ciam-card-content-line>

        <ciam-card-content-line>
          <template v-slot:label>{{ $t('deploymentDetail.customExtensions') }}</template>
          <template v-slot:content>
            <Promised :promise="customExtensions" v-if="showCustomExtensions">
              <template v-slot:pending>
                <ciam-loader class="py-4"></ciam-loader>
              </template>

              <template v-slot="data">
                <div>
                  <ciam-card class="mb-5">
                    <ciam-card-header>
                      <template v-slot:header>{{ $t('customExtensions.empty.header') }}</template>
                      <template v-slot:subtitle>{{ $t('customExtensions.empty.subtitle') }}</template>
                      <template v-slot:actions>
                        <ciam-button class="secondary" @click="upload()" :loading="uploading">
                          {{ $t('customExtensions.upload') }}
                          <template v-slot:right>
                            <ciam-icon name="fa-upload"></ciam-icon>
                          </template>
                        </ciam-button>
                      </template>
                    </ciam-card-header>
                  </ciam-card>

                  <ciam-list v-if="data.length > 0">
                    <ciam-list-item v-for="(extension, key) in data" :key="key">
                      <template v-slot:left>
                        <ciam-icon name="fa-file"></ciam-icon>
                        <span class="ml-2 flex-1 w-0 truncate">
                          <ciam-clipboard :showIcon="false" :payload="extension.fileId">
                            <ciam-text class="medium">{{ extension.filename }}</ciam-text>
                          </ciam-clipboard>
                          {{ extension.updatedAt | formatDate }}
                        </span>
                      </template>
                      <template v-slot:right>
                        <div v-if="extension.protected !== true">
                          <ciam-link @click="upload(Maybe.Just(extension))">
                            {{ $t('customExtensions.replace') }}
                          </ciam-link>
                          <ciam-link class="danger ml-2" @click="openRemovalConfirmationModal(extension)">
                            {{ $t('customExtensions.remove') }}
                          </ciam-link>
                        </div>
                      </template>
                    </ciam-list-item>
                  </ciam-list>
                </div>
              </template>
            </Promised>
            <div v-else>
              <ciam-card class="mb-5">
                <ciam-card-header>
                  <template v-slot:header>{{ $t('customExtensions.upsell.header') }}</template>
                  <template v-slot:subtitle>{{ $t('customExtensions.upsell.subtitle') }}</template>
                  <template v-slot:actions>
                    <ciam-button class="secondary" @click="contactUs()">
                      {{ $t('customExtensions.upsell.cta') }}
                      <template v-slot:right>
                        <ciam-icon name="fa-envelope"></ciam-icon>
                      </template>
                    </ciam-button>
                  </template>
                </ciam-card-header>
              </ciam-card>
            </div>
          </template>
        </ciam-card-content-line>
        <ciam-card-content-line type="full-width" v-if="problem">
          <template v-slot:content>
            <ciam-alert :title="problem.title" :description="problem.detail" :type="AlertStatus.ALERT"></ciam-alert>
          </template>
        </ciam-card-content-line>

      </ciam-card-content-lines>
    </ciam-card-content>
    <ciam-card-footer>
      <ciam-button
        data-e2e="deployment-delete"
        class="danger"
        v-if="isDeletable"
        @click="deleteDeploymentConfirmationModal.isOpened = true"
        :disabled="isDeploymentDeleting"
      >
        {{ $t('deploymentDetail.destroy') }}
        <template v-slot:right>
          <ciam-icon name="fa-trash"></ciam-icon>
        </template>
      </ciam-button>
    </ciam-card-footer>

    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="extensionRemovalConfirmationModal.isOpened">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            class="absolute inset-0 bg-gray-500 opacity-75"
            @click="toggleCustomExtensionRemovalConfirmationDialog()"
          ></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              @click="toggleCustomExtensionRemovalConfirmationDialog()"
              type="button"
              class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="sr-only">Close</span>
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <svg
                class="h-6 w-6 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {{ $t('customExtensions.removalConfirmationDialog.title') }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{
                    $t('customExtensions.removalConfirmationDialog.text1', {
                      filename: extensionRemovalConfirmationModal.extension.filename,
                    })
                  }}
                  <br />
                  {{ $t('customExtensions.removalConfirmationDialog.text2') }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              @click="removeExtension(extensionRemovalConfirmationModal.extension)"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {{ $t('customExtensions.removalConfirmationDialog.remove') }}
            </button>
            <button
              @click="toggleCustomExtensionRemovalConfirmationDialog()"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            >
              {{ $t('customExtensions.removalConfirmationDialog.cancel') }}
            </button>
          </div>

        </div>
      </div>
    </div>

    <b-modal
      :active.sync="deleteDeploymentConfirmationModal.isOpened"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <standard-modal
        :buttons="deleteDeploymentConfirmationModal.buttons"
        :item="deployment"
        @result="remove($event.result)"
      >
        <template v-slot:header>{{ $t('deleteDeploymentConfirmationModal.confirmRemoval') }}</template>
        <template v-slot:default="props">
          <p>
            <b>{{ $t('deleteDeploymentConfirmationModal.confirmRemovalQuestion') }}</b>
          </p>
          <hr />
          <p>{{ $t('deleteDeploymentConfirmationModal.confirmRemovalQuestionBis') }}</p>
          <hr />
          <p style="padding-top: 30px">
            {{ $t('deleteDeploymentConfirmationModal.name') }}: {{ props.item.name }}
            <ciam-input
              type="text"
              v-model="deleteDeploymentConfirmationModal.nameConfirmation"
              :placeholder="props.item.name"
            />
          </p>
        </template>
      </standard-modal>
    </b-modal>
  </div>
</template>

<script>
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamCardContent from '@/components/CiamCardContent';
import CiamCardContentLine from '@/components/CiamCardContentLine';
import CiamCardContentLines from '@/components/CiamCardContentLines';
import CiamCardFooter from '@/components/CiamCardFooter';
import { Notification } from 'vue-notifyjs';
import DeploymentService from '../DeploymentService';
import DeploymentStatusService from '../DeploymentStatusService';
import router, { routeNames } from '@/router';
import StandardModal from '@/components/StandardModal';
import CiamButton from '@/components/CiamButton';
import CiamIcon from '@/components/CiamIcon';
import PlanService from '@/pages/PlanService';
import CiamLink from '@/components/CiamLink';
import CiamList from '@/components/CiamList';
import CiamListItem from '@/components/CiamListItem';
import CustomExtensionService from './customextension/CustomExtensionService';
import i18n from '@/i18n';
import { Promised } from 'vue-promised';
import CiamText from '@/components/CiamText';
import Maybe from 'data.maybe-fgribreau';
import OrganizationService from '@/pages/organizations/OrganizationService';
import CiamInput from '@/components/CiamInput';
import CiamClipboard from '@/components/CiamClipboard.vue';
import DeploymentBusiness from '../DeploymentBusiness';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import CiamDeploymentVersion from '@/components/CiamDeploymentVersion'
import CiamUptimeBadge from '@/components/CiamUptimeBadge'

export default {
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  name: 'DeploymentDetail',
  components: {
    CiamInput,
    Promised,
    CiamText,
    CiamIcon,
    CiamCard,
    CiamCardHeader,
    CiamCardContent,
    CiamCardContentLine,
    CiamCardContentLines,
    CiamCardFooter,
    CiamButton,
    CiamLink,
    CiamList,
    CiamListItem,
    StandardModal,
    CiamClipboard,
    CiamAlert,
    CiamDeploymentVersion,
    CiamUptimeBadge
  },
  data() {
    return {
      Maybe: Maybe,
      customExtensions: new Promise(() => {
        // always loading promise
      }),
      AlertStatus: AlertStatus,
      lastKeycloakVersion: '',
      statistics: null,
      topology: null,
      topologyFeatureEnabled: false,
      uploading: false,
      organizationName: '...',
      isDeploymentDeleting: false,
      pollingDeploymentState: null,
      canIUpgrade: null,
      problem: null,
      extensionRemovalConfirmationModal: {
        isOpened: false,
        extension: {},
      },
      iframeUrl: '',
      deleteDeploymentConfirmationModal: {
        isOpened: false,
        nameConfirmation: '',
        buttons: [
          { text: this.$t('actions.cancel') },
          {
            text: this.$t('actions.delete'),
            classes: ['is-danger', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
    };
  },
  mounted() {
    DeploymentService.listUpgradeAvailable(this.deployment.id).then(resp => {
      this.canIUpgrade = resp.upgrades.length > 0;
    });
    this.refreshList();
  },
  updated() {
    // when "deployment" is updated, then update extension list
    this.refreshList();
  },
  methods: {
    planLocation() {
      if (this.deployment === null) {
        return '¯\\_(ツ)_/¯';
      }
      return this.$t('deploymentDetail.providers.' + this.deployment.provider) + ' ' + this.deployment.region;
    },
    viewOrganization() {
      return this.$router.resolve({
        name: routeNames.OrganizationDetails,
        query: {
          organization_id: this.deployment.organizationId,
        },
        params: {
          id: this.deployment.organizationId,
        },
      }).href;
    },
    async remove(deployment) {
      if (this.deleteDeploymentConfirmationModal.nameConfirmation !== deployment.name) {
        alert(this.$t('deleteDeploymentConfirmationModal.mismatch'));
        this.deleteDeploymentConfirmationModal.nameConfirmation = '';
        return false;
      }
      const { id, name } = deployment;
      let loader = this.showLoader();
      this.isDeploymentDeleting = true;
      try {
        const status = await DeploymentService.remove(deployment);
        if (status === 204) {
          DeploymentStatusService.cancelTracking(id);
          Notification.notify({
            message: this.$t('notifications.deploymentRemoved', { name: name }),
            type: 'info',
          });
          router.push({ name: routeNames.DeploymentList }).catch(() => {
          });
        }
      } catch (err) {
        this.$log.error('Error while removing the deployment', err);
      } finally {
        this.isDeploymentDeleting = false;
        loader.hide();
      }
    },

    contactUs() {
      this.$router
        .push({
          name: routeNames.TicketList,
        })
        .catch(() => {
        });
    },

    /**
     * @param {string} adminUrl - keycloak admin URL
     */
    openKeycloakConsole(adminUrl) {
      window.open(adminUrl);
    },

    getPercentRealms(statistics) {
      return DeploymentBusiness.getPercentRealms(statistics);
    },
    getPercentUsers(statistics) {
      return DeploymentBusiness.getPercentUsers(statistics);
    },
    getUsersProgressStyle(statistics) {
      return DeploymentBusiness.getUsersProgressStyle(statistics);
    },
    getRealmsProgressStyle(statistics) {
      return DeploymentBusiness.getRealmsProgressStyle(statistics);
    },

    /**
     * Upload or re-upload an extension (jar file)
     * @param {Maybe<Extension>} extension — specified (Maybe.Just(extension)) if this is a replace operation. Maybe.Nothing if this is a simple upload
     */
    upload(extension = Maybe.Nothing()) {
      const ipt = document.createElement('input');
      ipt.type = 'file';
      ipt.multiple = false;
      ipt.style.display = 'none';
      ipt.accept = 'application/java-archive';
      document.body.appendChild(ipt);
      ipt.click();
      ipt.onchange = () => {
        if (ipt.files.length !== 1) {
          return;
        }

        if (extension.isNothing && !confirm(i18n.t('customExtensions.upload_confirm', { name: ipt.files[0].name }))) {
          // this is a upload
          return;
        }

        if (extension.isJust && !confirm(i18n.t('customExtensions.replace_confirm', { name: ipt.files[0].name }))) {
          // this is a re-upload
          return;
        }

        this.uploading = true;
        let loader = this.showLoader();
        this.pollDeploymentState();
        CustomExtensionService.create(this.deployment.id, ipt.files[0], extension)
          .then(() => {
            Notification.notify({
              message: i18n.t('customExtensions.uploaded', { filename: ipt.files[0].name }),
              type: 'info',
            });
          })
          .catch((problem) => this.problem = problem)
          .finally(() => {
            this.uploading = false;
            loader.hide();
            this.refreshList();
          });
      };
    },

    removeExtension(extension) {
      this.toggleCustomExtensionRemovalConfirmationDialog();
      let loader = this.showLoader();
      this.pollDeploymentState();
      CustomExtensionService.remove(this.deployment.id, extension.fileId)
        .then(() => {
          Notification.notify({
            message: i18n.t('customExtensions.removed'),
            type: 'info',
          });
        })
        .catch((problem) => this.problem = problem)
        .finally(() => {
          loader.hide();
          this.refreshList();
        });
    },

    refreshList() {
      DeploymentService.getStatistics(this.deployment)
        .then((statistics) => (this.statistics = statistics))
        .catch((error) => {
          this.problem = error;
          this.statistics = null;
        });

      OrganizationService.get(this.organizationId)
        .then((organization) => {
          this.organizationName = organization.name;
        })
        .catch((problem) => {
          this.problem = problem;
          this.organizationName = '...';
        });

      if (PlanService.hasCustomExtensions(this.deployment)) {
        CustomExtensionService.list(this.deployment.id).then(
          (extensions) => (this.customExtensions = Promise.resolve(extensions)),
          (err) => (this.customExtensions = Promise.reject(err)),
        )
          .catch((problem) => this.problem = problem);
      }

      if (this.deployment.pricingPlan.dedicatedInstance) {
        DeploymentService.getTopology(this.deployment.id)
          .then((topology) => {
            this.topology = topology;
            // Old deployment not dedicated but without topology
            this.topologyFeatureEnabled = !(this.topology.gateway.publicIp == null || this.topology.gateway.publicIp === '');
          })
          .catch((reason) => {
            this.topologyFeatureEnabled = false;
            console.debug('topology could not be loaded', reason);
          });
      }
    },
    showLoader() {
      let _loader = this.$loading.show();
      return {
        hide() {
          _loader.hide();
        },
      };
    },
    pollDeploymentState() {
      if (this.pollingDeploymentState === null) {
        this.pollingDeploymentState = setInterval(() => {
          DeploymentService.get(this.deployment.id).then((deployment) => {
            if (this.status === 'STARTING' && deployment.status === 'RUNNING') {
              Notification.notify({
                message: i18n.t('deploymentDetail.running'),
                type: 'info',
              });
              clearInterval(this.pollingDeploymentState);
              this.pollingDeploymentState = null;
            }
            this.status = deployment.status;
          });
        }, 10000);
      }
    },
    toggleCustomExtensionRemovalConfirmationDialog() {
      this.extensionRemovalConfirmationModal.isOpened = !this.extensionRemovalConfirmationModal.isOpened;
    },
    openRemovalConfirmationModal(extension) {
      this.toggleCustomExtensionRemovalConfirmationDialog();
      this.extensionRemovalConfirmationModal.extension = extension;
    },
    isAdmin() {
      return this.$keycloak.hasRealmRole('ADMIN');
    },
  },
  computed: {
    isDeletable() {
      if (this.deployment === null) {
        return false;
      }
      return PlanService.isFree(this.deployment.pricingPlan.name) === true || this.$keycloak.hasRealmRole('ADMIN');
    },
    organizationId() {
      return this.deployment.organizationId;
    },
    status() {
      return this.deployment.status;
    },
    showCustomExtensions() {
      return PlanService.hasCustomExtensions(this.deployment);
    },
  },
  beforeDestroy() {
    if (this.pollingDeploymentState) {
      clearInterval(this.pollingDeploymentState);
    }
  },
};
</script>

<style lang="scss" scoped>
.link {
  @apply font-medium text-indigo-600 hover:text-indigo-500;
}
</style>
